body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
.admin-layout {
  min-height: 100vh;
}
.trigger {
  font-size: 20px;
  color: white;
  margin-left: 20px;
}
.trigger:hover {
  color: blue;
  transition: .6s;
}
.logo {
  color: white;
  padding: 16px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
